import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import WhyImg from "../../Assets/Images/Why.gif";
function Why(props) {
  return (
    <div className="home-box">
      <Container fluid={true}>
        <Row>
          <Col sm="2" xs="12"></Col>
          <Col sm="8" xs="12">
            <br />
            <h1 className="home-txt4">Why Choose Parcel-King?</h1>
            <h5 className="home-txt2" style={{ textAlign: "center" }}>
              Parcel-King is a collection and delivery service for letters,
              parcels and packages.
            </h5>
            <br />
            <Row>
              <Col sm="6" xs="12">
                <img
                  src={WhyImg}
                  className="home-why-img"
                  alt=" Parcel-King is a collection and delivery service for letters,
              parcels and packages."
                />
                <br /> <br />
              </Col>
              <Col sm="6" xs="12">
                <ul className="home-list">
                  <li style={{ marginBottom: 10 }}>
                    <h5 className="home-txt8">Business</h5>
                    <h6 className="home-txt9">
                      Deliver business packages and letters safely to your
                      clients.
                    </h6>
                  </li>
                  <li style={{ marginBottom: 10 }}>
                    <h5 className="home-txt8">Individuals</h5>
                    <h6 className="home-txt9">
                      Quick collection and safe delivery of your packages.
                    </h6>
                  </li>
                  <li style={{ marginBottom: 10 }}>
                    <h5 className="home-txt8">Pallets</h5>
                    <h6 className="home-txt9">
                      With just €30 per pallet, we collect & deliver it
                      anywhere, anytime.
                    </h6>
                  </li>
                  <li style={{ marginBottom: 10 }}>
                    <h5 className="home-txt8">Route Optimization</h5>
                    <h6 className="home-txt9">
                      Parcel-King mobile app helps navigate over 500 routes with
                      optimised solutions, saving your time like never before.
                    </h6>
                  </li>
                  <li style={{ marginBottom: 10 }}>
                    <h5 className="home-txt8">Shopify Store</h5>
                    <h6 className="home-txt9">
                      Connect your Shopify business with Parcel-King and leave
                      the rest to us as we take care of your delivery.
                    </h6>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
          <Col sm="2" xs="12"></Col>
        </Row>
      </Container>
      <br /> <br />
    </div>
  );
}
export default withRouter(Why);
