import React from "react";
import { withRouter } from "react-router-dom";
import { Container } from "react-bootstrap";
import PartnersItem from "../Partners/PartnersItem";
function Partners(props) {
  return (
    <div style={{ backgroundColor: "#eceff1" }}>
      <Container>
        <h1 className="home-txt4">Our Business Partners</h1>
        <br /> <br />
        <PartnersItem />
      </Container>
      <br />
    </div>
  );
}
export default withRouter(Partners);
