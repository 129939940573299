import React from "react";
import { Container, Table, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import Pallet from "../../Assets/Images/pallet.svg";
import Shopify from "../../Assets/Images/shopify.svg";
import Collect from "../../Assets/Images/collect.svg";
import THIRDrd from "../../Assets/Images/3rd party.svg";
function ServicesTable(props) {
  return (
    <Container fluid={true}>
      <h1 className="home-txt4">Services & Features</h1>
      <br /> <br />
      <Row>
        <Col sm="1"></Col>
        <Col sm="10">
          <Table responsive className="home-ServicesTable">
            <tr>
              <td style={{ padding: 0 }}>
                <div
                  className="home-ServicesTable-heading"
                  style={{
                    backgroundColor: "#eceff1",
                    color: "#000",
                    fontSize: 20,
                  }}
                >
                  <div>FEATURES</div>
                </div>
              </td>
              <td style={{ padding: 0 }}>
                <div
                  className="home-ServicesTable-heading"
                  style={{ backgroundColor: "#42a5f5" }}
                >
                  <img
                    src={Collect}
                    className="home-ServicesTable-image"
                    alt="parcel-king collect&delivery"
                  />
                  <div>Collect & Deliver</div>
                </div>
              </td>
              <td style={{ padding: 0 }}>
                <div
                  className="home-ServicesTable-heading"
                  style={{ backgroundColor: "#ab47bc" }}
                >
                  <img
                    src={Pallet}
                    className="home-ServicesTable-image"
                    alt="parcel-king Pallet/Bulk Service"
                  />
                  <div>Pallet/Bulk Service</div>
                </div>
              </td>
              <td style={{ padding: 0 }}>
                <div
                  className="home-ServicesTable-heading"
                  style={{ backgroundColor: "#26a69a" }}
                >
                  <img
                    src={THIRDrd}
                    className="home-ServicesTable-image"
                    alt="parcel-king Third Party C & D"
                  />
                  <div>Third Party C & D</div>
                </div>
              </td>
              <td style={{ padding: 0 }}>
                <div
                  className="home-ServicesTable-heading"
                  style={{ backgroundColor: "#66bb6a" }}
                >
                  <img
                    src={Shopify}
                    className="home-ServicesTable-image"
                    alt="shopify"
                  />
                  <div>Shopify</div>
                </div>
              </td>
            </tr>
            <tr className="home-ServicesTable-tr">
              <td style={{ padding: 0 }}>
                <div className="home-ServicesTable-td-head">Signature</div>
              </td>
              <td className="home-ServicesTable-td">
                <AiFillCheckCircle className="home-check" />
              </td>
              <td className="home-ServicesTable-td">
                <AiFillCheckCircle className="home-check" />
              </td>
              <td className="home-ServicesTable-td">
                <AiFillCheckCircle className="home-check" />
              </td>
              <td className="home-ServicesTable-td">
                <AiFillCheckCircle className="home-check" />
              </td>
            </tr>
            <tr className="home-ServicesTable-tr">
              <td style={{ padding: 0 }}>
                <div className="home-ServicesTable-td-head">
                  Nationwide Delivery
                </div>
              </td>
              <td className="home-ServicesTable-td">1-2 Working days</td>
              <td className="home-ServicesTable-td">Same Day Delivery</td>
              <td className="home-ServicesTable-td">1-2 Working days</td>
              <td className="home-ServicesTable-td">Same Day Delivery</td>
            </tr>
            <tr className="home-ServicesTable-tr">
              <td style={{ padding: 0 }}>
                <div className="home-ServicesTable-td-head">
                  International Delivery
                </div>
              </td>
              <td className="home-ServicesTable-td">3-7 Business days</td>
              <td className="home-ServicesTable-td">
                <AiFillCloseCircle className="home-uncheck" />
              </td>
              <td className="home-ServicesTable-td">3-7 Business days</td>
              <td className="home-ServicesTable-td">3-7 Business days</td>
            </tr>
            <tr className="home-ServicesTable-tr">
              <td style={{ padding: 0 }}>
                <div className="home-ServicesTable-td-head">Insured</div>
              </td>
              <td className="home-ServicesTable-td">
                <AiFillCheckCircle className="home-check" />
              </td>
              <td className="home-ServicesTable-td">
                <AiFillCheckCircle className="home-check" />
              </td>
              <td className="home-ServicesTable-td">
                <AiFillCheckCircle className="home-check" />
              </td>
              <td className="home-ServicesTable-td">
                <AiFillCheckCircle className="home-check" />
              </td>
            </tr>
            <tr className="home-ServicesTable-tr">
              <td style={{ padding: 0 }}>
                <div className="home-ServicesTable-td-head">Track & Trace</div>
              </td>
              <td className="home-ServicesTable-td">
                <AiFillCheckCircle className="home-check" />
              </td>
              <td className="home-ServicesTable-td">
                <AiFillCheckCircle className="home-check" />
              </td>
              <td className="home-ServicesTable-td">
                <AiFillCheckCircle className="home-check" />
              </td>
              <td className="home-ServicesTable-td">
                <AiFillCheckCircle className="home-check" />
              </td>
            </tr>
          </Table>
        </Col>
        <Col sm="1"></Col>
      </Row>
    </Container>
  );
}
export default withRouter(ServicesTable);
