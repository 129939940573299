import React, { useState } from "react";
import { Button, Form, Input, message } from "antd";
import { Row, Col } from "react-bootstrap";
import GooglePlaceInput from "../../Components/GooglePlaceInput";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { TiLocation } from "react-icons/ti";
import { RiScalesLine } from "react-icons/ri";
import CircularProgress from "@material-ui/core/CircularProgress";
import API from "../../config/API";
import Flip from "react-reveal/Flip";
import { withRouter } from "react-router-dom";
import { CheckPoligon } from "../../Redux/Services/CheckPoligon";
import InfoModal from '../../Components/infoModal/index';

function GetPrice(props) {
  const [loading, setLoading] = useState(false);
  const [info_modal, setInfoModal] = useState(false);
  const [type, setType] = useState("National");
  const [priceCard, setPriceCard] = useState(false);
  const [pick_country, setPick_country] = useState(null);
  const [deliver_country, setDeliver_country] = useState(null);
  const [result, setResult] = useState({});

  const [pickCharge, setpickCharge] = useState(0);

  const PickupLoaction = async (value) => {
    let CODE = null;
    let COUNTRY = null;
    value.address_components.forEach((entry) => {
      if (entry.types?.[0] === "country") {
        CODE = entry.short_name;
        COUNTRY = entry.long_name;
      }
    });
    setPick_country(CODE + "-" + COUNTRY);
    let geometry = value.geometry && value.geometry.location;
    let pol = [geometry.lat, geometry.lng];
    let checkpl = await CheckPoligon(pol);
    if (!checkpl) {
      setpickCharge(9.99);
    } else {
      setpickCharge(0);
    }
  };

  const DeliveryLoaction = (value) => {
    let CODE = null;
    let COUNTRY = null;
    value.address_components.forEach((entry) => {
      if (entry.types?.[0] === "country") {
        CODE = entry.short_name;
        COUNTRY = entry.long_name;
      }
    });
    setDeliver_country(CODE + "-" + COUNTRY);
  };

  const onFinish = (value) => {
    setLoading(true);
    let obj = {
      type: type,
      pick_country: pick_country,
      deliver_country: deliver_country,
      Packages: [{ package_weight: value.package_weight }],
    };
    fetch(process.env.REACT_APP_API_KEY + API.GET_BOOKING_PRICE, {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(obj),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setLoading(false);
        if (responseJson.status) {
          setResult(responseJson.data);
          setPriceCard(true);
        } else {
          message.error("Sorry No price Available.");
          setPriceCard(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error("Sorry No price Available.");
      });
  };

  const bookNow = () => {
    props.history.push("/PayBooking");
  };

  return (
    <>
      {priceCard ? (
        <Flip left>
          <div className="home-GetPrice">
            <div className="home-tabBox">
              <div className={"home-tabBox-btn-active"}>Price Details</div>
            </div>
            <Row>
              <Col sm="7" xs="12" style={{ padding: 0 }}>
                <div className="home-priceBox-item">
                  <TiLocation className="home-placeInput-icon1" />
                  <div>Pickup &nbsp;: {pick_country}</div>
                </div>
                <div className="home-priceBox-item">
                  <TiLocation className="home-placeInput-icon2" />
                  <div>Delivery : {deliver_country}</div>
                </div>
                <div className="home-priceBox-item">
                  <RiScalesLine className="home-placeInput-icon1" />
                  <div>Weight &nbsp;: {result && result.totalWeight} kg</div>
                </div>
              </Col>
              <Col sm="5" xs="12" style={{ padding: 0 }}>
                <Button block type="primary" onClick={() => bookNow()}>
                  <span style={{ color: "#000", fontSize: 20 }}>
                    €{" "}
                    {result &&
                      Number(
                        Number(result.grand_total) + Number(pickCharge)
                      ).toFixed(2)}
                  </span>
                  {"  "}- Book Now
                </Button>
                <div
                  className="home-GetPrice-back"
                  onClick={() => setPriceCard(false)}
                >
                  Back To Form
                </div>
              </Col>
            </Row>
          </div>
        </Flip>
      ) : (
        <div className="home-GetPrice">
          <div className="home-tabBox">
            <div
              className={
                type === "National"
                  ? "home-tabBox-btn-active"
                  : "home-tabBox-btn"
              }
              onClick={() => setType("National")}
            >
              National
            </div>
            <div
              className={
                type !== "National"
                  ? "home-tabBox-btn-active"
                  : "home-tabBox-btn"
              }
              onClick={() => setType("International")}
            >
              International
            </div>
          </div>
          <Form
            onFinish={
              // onFinish
              () => setInfoModal(true)
            }
          >
            <Row>
              <Col sm="6" xs="12" style={{ padding: 0 }}>
                <Form.Item
                  style={{ margin: 2 }}
                  name="pick_country"
                  rules={[{ required: true, message: "required" }]}
                >
                  <div className="home-placeInput">
                    <HiOutlineLocationMarker className="home-placeInput-icon1" />
                    <GooglePlaceInput
                      placeholder="Pickup Location"
                      onChange={(place) => PickupLoaction(place)}
                      types={"geocode"}
                      country={"IE"}
                      region={"IE"}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col sm="6" xs="12" style={{ padding: 0 }}>
                <Form.Item
                  style={{ margin: 2 }}
                  name="deliver_country"
                  rules={[{ required: true, message: "required" }]}
                >
                  <div className="home-placeInput">
                    <TiLocation className="home-placeInput-icon2" />
                    <GooglePlaceInput
                      placeholder="Destination"
                      onChange={(place) => DeliveryLoaction(place)}
                      types={"geocode"}
                      region={type === "National" ? "IE" : "ALL"}
                      country={type === "National" ? "IE" : "ALL"}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col sm="6" xs="6" style={{ padding: 0 }}>
                <Form.Item
                  style={{ margin: 2 }}
                  name="package_weight"
                  rules={[{ required: true, message: "required" }]}
                >
                  <Input
                    placeholder="Weight ( kg )"
                    prefix={<RiScalesLine className="home-placeInput-icon1" />}
                    type="number"
                  />
                </Form.Item>
              </Col>
              <Col sm="6" xs="6" style={{ padding: 0 }}>
                <Form.Item style={{ margin: 2 }}>
                  <Button block type="primary" htmlType="submit">
                    {loading ? <CircularProgress size={20} /> : "Get Price"}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <InfoModal
            isVisible={info_modal}
            close={() => setInfoModal(false)}
          />
        </div>
      )}
    </>
  );
}
export default withRouter(GetPrice);
