import { Button, Modal } from "antd";
import "./styles.css";
import React, { useState } from "react";
import { AiFillCloseCircle } from 'react-icons/ai'

const InfoModal = (props) => {
  return (
    <>
      <Modal
        
        centered
        open={props.open}
        onOk={() => props.setOpen(false)}
        onCancel={() => props.setOpen(false)}
        width={1000}
        footer={false}
        closeIcon={<AiFillCloseCircle  style={{color:"#ffc400",fontSize:22}} onClick={() => props.setOpen(false)}/>}
      >
        <div className="infomenucardContnet">
          <div>
            <img
              src={require(`.././Images/stars.png`)}
              className="infomenuicon"
              alt="menus"
            />
          </div>
          <div>
            {/* <div className="infomenuitemtxt">Info</div> */}
            <div className="infomenusubtxt2">
              Dear Customer, 
             <p> We wish to inform you that we will remain closed
              from 8th April 2023 to Easter Monday 10th April 2023, and we only
              deliver your parcel in county Dublin on 7th April. We will be back
              in operation on April 11, 2023, and will be more than happy to
              deliver your parcels as usual.</p>
               Happy Easter to you and your
              families!
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default InfoModal;
