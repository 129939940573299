import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import Bookings from "../../Assets/Images/booking-count.gif";
import Drivers from "../../Assets/Images/Driver-count.gif";
import Delivery from "../../Assets/Images/Delivery-count.gif";
import Journey from "../../Assets/Images/Journey-count.gif";
function Counts(props) {
  const [journyCount, setjournyCount] = useState(4146);
  const [driverCount, setdriverCount] = useState(
    Number(278) + Number(props.data.driverCount)
  );
  const [packageCount, setpackageCount] = useState(583992);
  const [bookingCount, setbookingCount] = useState(300);

  const getBusinessDatesCount = (startDate, endDate) => {
    let count = 0;
    const curDate = new Date(startDate);
    while (curDate <= endDate) {
      const dayOfWeek = curDate.getDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) count++;
      curDate.setDate(curDate.getDate() + 1);
    }
    return count;
  };

  useEffect(() => {
    let startDate = new Date("08-01-2021");
    let endDate = new Date();
    var numOfDates = getBusinessDatesCount(startDate, endDate);
    let averageGls = numOfDates * 120;
    let averagePackageGls = averageGls * 1200;

    let journy = averageGls + (100000 + Number(props.data.journyCount)) / 50;
    setjournyCount(journy);

    let packages =
      Number(packageCount) +
      Number(averagePackageGls) +
      Number(props.data.packageCount);
    setpackageCount(packages);

    let booking = Number(bookingCount) + Number(props.data.bookingCount);
    setbookingCount(booking);
  }, []);

  return (
    <Container>
      <h1 className="home-txt4">More than 10M packages delivered.</h1>
      <h5 className="home-txt5">
        Parcel-King working with International partners and delivers packages
        with qualtiy drivers
      </h5>
      <br />
      <Row>
        <Col sm="3" xs="6" className="home-count-col">
          <div className="home-count-box">
            <img
              src={Bookings}
              className="home-count-image"
              alt="parcel-king delivery"
            />
            <h2 className="home-txt6">{Number(bookingCount).toFixed(0)}+</h2>
            <h6 className="home-txt7">Bookings</h6>
          </div>
        </Col>
        <Col sm="3" xs="6" className="home-count-col">
          <div className="home-count-box">
            <img
              src={Journey}
              className="home-count-image"
              alt="parcel-king pickup"
            />

            <br />
            <h2 className="home-txt6">{Number(journyCount).toFixed(0)}+</h2>
            <h6 className="home-txt7">Journeys</h6>
          </div>
        </Col>
        <Col sm="3" xs="6" className="home-count-col">
          <div className="home-count-box">
            <img
              src={Drivers}
              className="home-count-image"
              alt="parcel-king booking"
            />
            <h2 className="home-txt6">{Number(driverCount).toFixed(0)}+</h2>
            <h6 className="home-txt7">Drivers</h6>
          </div>
        </Col>
        <Col sm="3" xs="6">
          <div className="home-count-box">
            <img
              src={Delivery}
              className="home-count-image"
              alt="parcel-king collection"
            />
            <h2 className="home-txt6">{Number(packageCount).toFixed(0)}+</h2>
            <h6 className="home-txt7">Delivered</h6>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
export default withRouter(Counts);
