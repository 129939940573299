import React from "react";
import { Button, Form, Input } from "antd";
import { Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
function TrackBooking(props) {
  const onFinish = (value) => {
    props.history.push("/track/" + value.packageid);
  };

  return (
    <div className="home-TrackBooking">
      <Form onFinish={onFinish}>
        <Row>
          <Col sm="8" xs="8" style={{ padding: 0 }}>
            <Form.Item
              style={{ margin: 0 }}
              name="packageid"
              rules={[
                {
                  required: true,
                  message: (
                    <div style={{ marginLeft: 20 }}>
                      Enter Booking Number/ID
                    </div>
                  ),
                },
              ]}
            >
              <Input
                placeholder="Enter your tracking number"
                style={{
                  marginLeft: 10,
                  backgroundColor: "rgba(255, 255, 255, 0.625)",
                  textAlign: "center",
                  padding: 10,
                  height: 50,
                }}
              />
            </Form.Item>
          </Col>
          <Col sm="4" xs="4" style={{ padding: 0 }}>
            <Form.Item style={{ margin: 0 }}>
              <Button
                block
                type="primary"
                htmlType="submit"
                style={{ height: 50, borderRadius: "0px 10px 10px 0px" }}
              >
                Track
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
export default withRouter(TrackBooking);
