import React from "react";
import { Container } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import Routes from "../../Assets/Images/routes.png";
import Booking from "../../Assets/Images/booking.png";
import Timesheet from "../../Assets/Images/timesheet.png";
import Report from "../../Assets/Images/report.png";
import Vehicle from "../../Assets/Images/vehicle.png";
import Cheklist from "../../Assets/Images/cheklist.png";
import Driver from "../../Assets/Images/driver.png";
import Invoice from "../../Assets/Images/invoice.png";
function ComplimentaryServices() {
  return (
    <Container>
      <h1 className="home-txt4">Parcel-King's Complementary Services</h1>
      <br />
      <br />
      <div className="home-list2">
        <div>
          <img src={Routes} style={{ width: 60 }} />
        </div>
        <div className="home-list2Box">
          <h5 className="home-txt8">Route Journey Organiser</h5>
          <h6 className="home-txt9">
            If you drive for a living or run a business where transportation and
            logistics play a prime role, you know how vital route optimisation
            can be. With this in mind, Parcel-King mobile app changes the way we
            travel and work.
          </h6>
        </div>
      </div>
      <div className="home-list2">
        <div>
          <img src={Booking} style={{ width: 60 }} />
        </div>
        <div className="home-list2Box">
          <h5 className="home-txt8">Booking System</h5>
          <h6 className="home-txt9">
            Parcel-King has a seamless online booking system for any business
            that needs scheduling and managing their bookings.
          </h6>
        </div>
      </div>
      <div className="home-list2">
        <div>
          <img src={Timesheet} style={{ width: 60 }} />
        </div>
        <div className="home-list2Box">
          <h5 className="home-txt8">Timesheet</h5>
          <h6 className="home-txt9">
            Parcel-King gives businesses a unique perspective of employees’
            productivity and daily activities. You can analyse employee
            productivity, effectiveness & focus, along with 360-degree
            monitoring of their workflow.
          </h6>
        </div>
      </div>
      <div className="home-list2">
        <div>
          <img src={Report} style={{ width: 60 }} />
        </div>
        <div className="home-list2Box">
          <h5 className="home-txt8">Report</h5>
          <h6 className="home-txt9">
            Parcel-King understands that it is important to understand the worth
            of your small business using a few essential valuation ratios,
            business reports and infographics.
          </h6>
        </div>
      </div>
      <div className="home-list2">
        <div>
          <img src={Vehicle} style={{ width: 60 }} />
        </div>
        <div className="home-list2Box">
          <h5 className="home-txt8">Vehicle Repairs</h5>
          <h6 className="home-txt9">
            Have easy and quick access to various vehicle repair services.
          </h6>
        </div>
      </div>
      <div className="home-list2">
        <div>
          <img src={Cheklist} style={{ width: 60 }} />
        </div>
        <div className="home-list2Box">
          <h5 className="home-txt8">Vehicle Valuation</h5>
          <h6 className="home-txt9">
            Check the current market value of your car based on the year, brand,
            model, variant, kilometres, and city. Basically, it is a used car
            price estimation tool.
          </h6>
        </div>
      </div>
      <div className="home-list2">
        <div>
          <img src={Driver} style={{ width: 60 }} />
        </div>
        <div className="home-list2Box">
          <h5 className="home-txt8">Driver Management</h5>
          <h6 className="home-txt9">
            Keep track of all your drivers and their information. You can add or
            remove drivers as needed.
          </h6>
        </div>
      </div>
      <div className="home-list2">
        <div>
          <img src={Invoice} style={{ width: 60 }} />
        </div>
        <div className="home-list2Box">
          <h5 className="home-txt8">Invoice</h5>
          <h6 className="home-txt9">
            Create invoices for your users, driver and retailers. You can then
            View, Download, and manage your invoices.
          </h6>
        </div>
      </div>
      <br />
    </Container>
  );
}
export default withRouter(ComplimentaryServices);
