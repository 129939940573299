import React from "react";
import "./styles.css";
import Footer from "../../Components/Footer";
import HomeHeader from "../../Components/HomeHeader";
import { Container } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import MainHeader from "../../Components/MainHeader";
class CollectService extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="Page3Container">
        <Container className="Page3MainBox">
          <Fade left>
            <img
              src={require("../../Images/WebImages/post.png")}
              className="Page3Box2image"
              alt="logo"
            />
          </Fade>
          <h4 className="Page3SubHeading">Packages </h4>
          <hr />
          <div className="Page3txt">
            We collect, deliver or post letter, parcels, and packages not more than 50kg
            for now.
          </div>
          <h4 className="Page3SubHeading">Delivery </h4> <hr />
          <div className="Page3txt">
            We deliver parcels and packages for shipping companies like FEDEX and
            online stores like Amazon.
          </div>
          <h4 className="Page3SubHeading">Deliveries</h4> <hr />
          <div className="Page3txt">
            We deliver packages in any location within the current cities that
            we operate in. If you want to us to work for you, please contact our
            sales team- 01-5549629.
          </div>
          <h4 className="Page3SubHeading">Transportation</h4> <hr />
          <div className="Page3txt">
            {" "}
            We handle bulk packages, pallets and many more, if you want to ship
            a pallet with us, just book online or call our sales team.
          </div>
          <h4 className="Page3SubHeading">Storage </h4> <hr />
          <div className="Page3txt">
            We are currently working on our storage facility and this service
            will be available soon nationwide.
          </div>
          <br />
          <Fade left>
            <img
              src={require("../../Images/WebImages/pack.png")}
              className="Page3Box2image"
              alt="logo"
            />
          </Fade>
        </Container>
      </div>
    );
  }
}
export default CollectService;
