import React from "react";
import "./style.css";
import Header from "./../../Components/OtherHeader";
import Footer from "./../../Components/Footer";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import { Link } from "react-router-dom";

function CustomerAdvocateForm() {
  const [isTrackedPost, setIsTrackedPost] = useState(true);
  const [isCompensationStamp, setIsCompensationStamp] = useState(true);
  const [isCompensationCheque, setIsCompensationCheque] = useState(true);
  return (
    <div>
      <Header />
      <div className="container">
        <div className="section1">
          <div className="main-heading">
            <h2>Parcel-King complaint and dispute resolution procedures </h2>
          </div>
          <div className="section-content">
            <p className="content-item">
              If you are not satisfied with the outcome of your complaint you
              may appeal your case to the Customer Advocate by completing the
              application below.
            </p>
            <p className="content-item">
              You should only complete this form when you have fully exhausted
              the Parcel King Customer Service procedures for resolving disputes
              and have received a final reply.
            </p>
            <p className="content-item">
              If this is the first time you are contacting us about this matter
              please use our <Link to="/contact-us" className="GettingItSorted-link">contact us</Link>  or{" "}
              <Link   className="GettingItSorted-link">online mails enquiry </Link>
              form.
            </p>
            <p className="content-item">
              This application and its processing is subject to the following
              timelines:
            </p>

            <ul className="listitem">
              <li>
                <p className="content-item">
                  Deadline for lodging cases with the Advocate: 30 calendar days
                  from date of final decision communicated by Parcel King
                  Customer Service.
                </p>
              </li>

              <li>
                <p className="content-item">
                  Interim response from Customer Advocate: By return if
                  submitted electronically or 3 days if by post.
                </p>
              </li>

              <li>
                <p className="content-item">
                  Final response from Customer Advocate: 30 calendar days
                  following your first contact.
                </p>
              </li>

              <h2 className="item-subheading">Next Steps</h2>

              <p className="content-item">
                To complete the Customer Advocate contact form you must have:
              </p>

              <li>
                <p className="content-item">
                  Your Customer Service enquiry number
                </p>
              </li>

              <li>
                <p className="content-item">Date you made your complaint</p>
              </li>

              <li>
                <p className="content-item">
                  Date the final decision was sent to you
                </p>
              </li>
            </ul>
            <p className="content-item">
              Information submitted to the Customer Advocate is maintained by An
              Post and retained for 6 years. It is used by the Customer Advocate
              to assess your submission and may also be used by Parcel King if
              you proceed to the Small Claims Court or Commission for
              Communications Regulation. An Post also use this information for
              customer services purposes. An Post’s data protection policy can
              be accessed at <a href="#">parcel-king.com/privacy</a>.
            </p>

            <p className="content-item">
              If you are not satisfied with the outcome of your complaint you
              may appeal your case to the Customer Advocate by completing the
              application below.
            </p>
          </div>
        </div>

        <div className="section2">
          <h2 className="item-subheading">Customer advocate contact form</h2>
          <div className="form-content">
            <Row>
              <Col xs="12">
                <Form.Control
                  size="lg"
                  type="text"
                  placeholder="CS/WB/CC Customer Service Case Number"
                />
              </Col>
            </Row>
          </div>

          <div className="form-content">
            <div className="form-headings">
              <p>Date original complaint made</p>
            </div>
            <div className="form-item">
              <Row>
                <Col xs="12">
                  <Form.Control size="lg" type="date" placeholder="Email" />
                </Col>
              </Row>
            </div>
          </div>

          <div className="form-content">
            <div className="form-headings">
              <p>Date of the final decision letter sent to you</p>
            </div>
            <div className="form-item">
              <Row>
                <Col>
                  <Form.Control size="lg" type="date" placeholder="Email" />
                </Col>
              </Row>
            </div>

            <div className="form-item">
              <select>
                <option disabled={true}>Open this select menu</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
          </div>

          <div className="form-content">
            <div className="form-headings">
              <p>Your Details</p>
            </div>
            <div className="form-item">
              <Row>
                <Col xs="12">
                  <Form.Control
                    size="lg"
                    type="text"
                    placeholder="First name"
                  />
                </Col>
              </Row>

              <Row>
                <Col xs="12">
                  <Form.Control size="lg" type="text" placeholder="Last Name" />
                </Col>
              </Row>

              <Row>
                <Col xs="12">
                  <Form.Control size="lg" type="email" placeholder="Email" />
                </Col>
              </Row>
            </div>
          </div>

          <div className="form-content">
            <div className="form-headings">
              <p>Is this complaint about tracked post?</p>
            </div>
            <div className="form-item">
              <Row>
                <Col md={6}>
                  <Button
                    onClick={() => {
                      setIsTrackedPost(true);
                    }}
                    style={{
                      backgroundColor: isTrackedPost ? "#FFC400" : "#cfd8dc",
                    }}
                    className="selectButton"
                  >
                    Yes
                  </Button>
                  <Button
                    onClick={() => {
                      setIsTrackedPost(false);
                    }}
                    style={{
                      backgroundColor: isTrackedPost ? "#cfd8dc" : "#FFC400",
                    }}
                    className="selectButton"
                  >
                    No
                  </Button>
                </Col>
                <Col md={6}></Col>
              </Row>

              {isTrackedPost ? (
                <div style={{ marginTop: "10px" }}>
                  <Row>
                    <Col xs="12">
                      <Form.Control
                        size="lg"
                        type="text"
                        placeholder="Your Teacking ID or Order Number"
                      />
                    </Col>
                  </Row>
                </div>
              ) : null}
            </div>
          </div>

          <div className="form-content">
            <div className="form-headings">
              <p>Have you received compensation?</p>
            </div>
            <div className="form-item">
              <h5 className="small-head">Stamps</h5>
              <Row>
                <Col md={6}>
                  <Button
                    onClick={() => {
                      setIsCompensationStamp(true);
                    }}
                    style={{
                      backgroundColor: isCompensationStamp
                        ? "#FFC400"
                        : "#cfd8dc",
                    }}
                    className="selectButton"
                  >
                    Yes
                  </Button>{" "}
                  <Button
                    onClick={() => {
                      setIsCompensationStamp(false);
                    }}
                    style={{
                      backgroundColor: isCompensationStamp
                        ? "#cfd8dc"
                        : "#FFC400",
                    }}
                    className="selectButton"
                  >
                    No
                  </Button>
                </Col>
                <Col md={6}></Col>
              </Row>
              {isCompensationStamp ? (
                <div style={{ marginTop: "10px" }}>
                  <Row>
                    <Col xs="12">
                      <Form.Control
                        size="lg"
                        type="text"
                        placeholder="Value €"
                      />
                    </Col>
                  </Row>
                </div>
              ) : null}
            </div>

            <div className="form-item">
              <h5 className="small-head">Cheque</h5>

              <Row>
                <Col md={6}>
                  <Button
                    onClick={() => {
                      setIsCompensationCheque(true);
                    }}
                    style={{
                      backgroundColor: isCompensationCheque
                        ? "#FFC400"
                        : "#cfd8dc",
                    }}
                    className="selectButton"
                  >
                    Yes
                  </Button>{" "}
                  <Button
                    onClick={() => {
                      setIsCompensationCheque(false);
                    }}
                    style={{
                      backgroundColor: isCompensationCheque
                        ? "#cfd8dc"
                        : "#FFC400",
                    }}
                    className="selectButton"
                  >
                    No
                  </Button>
                </Col>
                <Col md={6}></Col>
              </Row>
              {isCompensationCheque ? (
                <div style={{ marginTop: "10px" }}>
                  <Row>
                    <Col xs="12">
                      <Form.Control
                        size="lg"
                        type="text"
                        placeholder="Value €"
                      />
                    </Col>
                  </Row>
                </div>
              ) : null}
            </div>
          </div>

          <div className="form-content">
            <div className="form-headings">
              <p>Notes</p>
            </div>
            <div className="text-area">
              <Row>
                <Col xs="12">
                  <Form.Control
                    placeholder="Please provide some details of why are you dissatisfied with the decision of a Parcel King Customer Service on your commplaint. (Max. 280 Characters"
                    className="text-area-box"
                    maxLength={280}
                    as="textarea"
                    rows={6}
                  />
                </Col>
              </Row>
            </div>
          </div>
          <div className="submitButton">
            <Button>Submit Appeal</Button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CustomerAdvocateForm;
