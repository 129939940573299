import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import CloseIcon from "@material-ui/icons/Close";
import { withRouter } from "react-router-dom";
class MobileMenu extends React.PureComponent {
  navigate = (screen) => {
    this.props.close();
    this.props.history.push(screen);
  };
  render() {
    return (
      <Dialog
        open={this.props.visible}
        onClose={() => this.props.close()}
        fullScreen
      >
        <div className="MobileMenu">
          <div className="MobileMenuHeader">
            <div></div>
            <div onClick={() => this.props.close()}>
              <CloseIcon style={{ fontSize: 30, color: "#fff" }} />
            </div>
          </div>
          <div className="MobileMenuBox">
            <Fade bottom>
              <div
                className="MobileMenutxt1"
                onClick={() => this.navigate("/")}
              >
                home
              </div>
            </Fade>
            <Fade bottom>
              <div
                className="MobileMenutxt1"
                onClick={() => this.navigate("/page1")}
              >
                Post
              </div>
            </Fade>
            <Fade bottom>
              <div
                className="MobileMenutxt1"
                onClick={() => this.navigate("/page2")}
              >
                Collect
              </div>
            </Fade>
            <Fade bottom>
              <div
                className="MobileMenutxt1"
                onClick={() => this.navigate("/Page3")}
              >
                Packages
              </div>
            </Fade>
            <Fade bottom>
              <div
                className="MobileMenutxt1"
                onClick={() => this.navigate("/partners")}
              >
                Partners
              </div>
            </Fade>
            <Fade bottom>
              <div
                className="MobileMenutxt1"
                onClick={() => this.navigate("/login")}
              >
                Login now
              </div>
            </Fade>
            <Fade bottom>
              <div
                className="MobileMenutxt1"
                onClick={() => this.props.mobileRegister()}
              >
                Register
              </div>
            </Fade>
          </div>
        </div>
      </Dialog>
    );
  }
}
export default withRouter(MobileMenu);
