const styles = {
  Accordion: {
    boxShadow: "0px 0px 0px rgba(0, 0, 0,0)",
    borderBottom: "1px solid #DCDCDC",
    padding: 10,
  },
    inputBox: {
      fontFamily: "Campton-Light",
      width: "100%",
      borderWidth: 2,
      padding: 10,
      borderRadius: 4,
    },
  };
  export default styles;