import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Select, AutoComplete, Spin } from "antd";
import axios from "axios";
import { debounce } from "lodash";
import "./styles.css";

const GooglePlaceInput = (props) => {
  const [predictions, setPredictions] = useState([]);
  const [isPlacePredictionsLoading, setIsPlacePredictionsLoading] =
    useState(false);
 

  const onSearch = (searchText) => {
    if (searchText && searchText.length >= 3) {
      setIsPlacePredictionsLoading(true);
      executeDelayedOnType(searchText);
    }
    
  };

  const executeDelayedOnType = debounce((value) => {
    setIsPlacePredictionsLoading(true);
    //   const options = { types: "geocode", country: "IE", region: "IE" };
    fetch(
      `https://yp52kab7h7.execute-api.eu-west-1.amazonaws.com/dev/autoComplete/${value}/${props.types}/${props.country}/${props.region}`
    )
      .then((res) => res.json())
      .then((json) => {
        setIsPlacePredictionsLoading(false);
        const options = json?.data?.predictions?.map((prediction) => {
          return {
            value: prediction.description,
            label: prediction.description,
            place_id: prediction.place_id
          };
        });
        setPredictions(options);
      });
      
  }, 2000);

  const onSelect = async (val,option) => {
    console.log("palceId ==" + option.place_id);
    const config = {
      method: "get",
      url:
        "https://yp52kab7h7.execute-api.eu-west-1.amazonaws.com/dev/getPlaceDetails/" +
        option.place_id,
      headers: {}
    };
    const resopnse = await axios(config);
    const placeDetails = resopnse.data.data.result;
    props.onChange(placeDetails);
    setPredictions([]);
  };
 
  return (
    <div className="autocompletewrapper">
      <AutoComplete
        // notFoundContent={<Spin />}
        className="GooglePlaceInput"
        options={predictions}
        style={{
          width: 180,
          border:'none',
          outline:'none' 
        }}
        onSelect={(val, option) => onSelect(val, option)}
        onSearch={onSearch}
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
      />
      {isPlacePredictionsLoading && <Spin className="autocompleteloadingindicator" />}
    </div>
  );
};
export default withRouter(GooglePlaceInput);
