import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer";
import OtherHeader from "../../Components/OtherHeader";

export default class TermsCondition extends Component {
  render() {
    return (
      <div className="PartnersContainer">
        <OtherHeader />
        <br />
        <Container className="PartnersheaderBox">
          <br />
          <h3 className="PartnersheaderTxt">Terms and Conditions</h3>
        </Container>
        <Container className="TermsMainBox d-none">
          <div className="Termstxt">
            Parcel-King will organise the refund of the maximum amount possible
            based on the information and documentation provided by its customers
            and in line with the legal entitlements and obligations of the
            customer within the tax law of the country from which the tax refund
            is being reclaimed.
          </div>
          <div className="Termstxt">
            Parcel-King will organise the refund of the maximum amount possible
            based on the information and documentation provided by its customers
            and in line with the legal entitlements and obligations of the
            customer within the tax law of the country from which the tax refund
            is being reclaimed.
          </div>
          <div className="Termstxt">
            Where there is a change in the applicable tax law in the country
            from which the tax refund is being reclaimed or tax return filed, or
            in the interpretation or implementation of the rules and laws by the
            tax authorities of that country, Parcel-King will meet its
            commitment to organise the refund or return to the degree allowed by
            the law. The decision of the exact value of any refund due or tax
            amount owed is the decision of the relevant Tax Authority.
          </div>
          <div className="Termstxt">
            Parcel-King will provide the refund or return as expediently as
            possible, subject to the provision of the necessary documents by the
            customers and the efficient processing of the refund application or
            return filing by the relevant tax office.
          </div>
          <h6 className="TermsSubHeading1">
            Exceptions to our client tax responsibilities
          </h6>
          <div className="Termstxt">
            Parcel-King is not responsible for the refund or return of tax
            where:
          </div>
          <div className="Termstxt">
            the customer has provided information which is false, inaccurate,
            incorrect, insufficient or misleading in any way.
          </div>
          <div className="Termstxt">
            the customer has under or over declared income.
          </div>
          <div className="Termstxt">
            the customer circumstance changed in the process of tax filing or
            refund.
          </div>
          <div className="Termstxt">
            the customer has already received a refund.
          </div>
          <div className="Termstxt">
            the customer has already applied for a refund, either directly or
            through another person, natural or legal.
          </div>
          <div className="Termstxt">
            the customer owes money to the tax authority.
          </div>
          <div className="Termstxt">
            the tax authority has different information on its system than that
            provided by the customer.
          </div>
          <div className="Termstxt">
            the tax authority information leads to a decision that the customer
            is not due a refund.
          </div>
          <br />
          <div className="Termstxt">
            Where there is a possible case for appeal within the law, such
            measures will be taken by Parcel-King, where the customer
            information enables it to do so.
          </div>
          <br />
          <div className="Termstxt">
            Parcel-King will endeavour to help the customer resolve these
            issues, provided it receives all necessary information and
            instruction from the customer.
          </div>
          <br />
          <h6 className="TermsSubHeading1">Power of Attorney</h6>
          <div className="Termstxt">
            When the customer signs a Power of Attorney permitting Parcel-King
            to organise his/her return or refunds, he/she is agreeing to pay the
            fee to Parcel-King for the organisation of the return or refund. If
            the customer has signed a Power of Attorney authorising Parcel-King
            to organise his/her return or refunds, and has, with or without his
            or her knowledge, applied or authorised any other person, natural or
            legal, to organise the same return or refund, he/she will owe Parcel
            King the fee for the organisation of a return or refund,
            notwithstanding that the customer has obtained a refund or filed a
            return otherwise than with the assistance of Parcel-King.
          </div>
          <br />
          <div className="Termstxt">
            If the relevant tax office sends the due refund to the customer
            directly, rather than to Parcel-King, the customer still owes the
            processing fee in full to Parcel-King for the service rendered,
            which resulted in the issuing of the refund.
          </div>
          <br />
          <div className="Termstxt">
            If the customer directs Parcel-King to pay the tax on his/her
            behalf, the customer still owes the processing fee in full to Parcel
            King for the service rendered, which resulted in the filing the tax
            and paying the tax.
          </div>
          <br />
          <div className="Termstxt">
            If the customer does not pay this fee, pursuant to the information
            above on a voluntary basis or on request from Parcel-King, Parcel
            King reserves the right to:
          </div>
          <div className="Termstxt">
            Take legal action to pursue the customer for the fee due. Publish
            the name of the customer as a Bad Debtor in the local newspaper, on
            the Parcel-King website and other media if the fee has not been paid
            within the rational terms given. Report the customer as a Bad Debtor
            to the relevant financial institutions in the country of the
            customer.
          </div>
          <div className="Termstxt">
            Once authorised and registered as your tax agent with the relevant
            Tax Authority, all correspondence regarding your application must be
            handled directly by Parcel-King. Any measure taken by you, the
            customer, to make direct contact with the tax office without first
            consulting Parcel-King will be considered as a breach of contract
            and our relevant processing fee will still apply for all processing
            work completed by us during the application process.
          </div>
          <br />
          <div className="Termstxt">
            By signing and completing our documentation:
          </div>
          <div className="Termstxt">
            You understand that you will be contacted by Parcel-King after using
            any of the online tax calculator(s) or app. You can opt out of
            receiving any further correspondence by emailing
            info@taxgoglobal.com.
          </div>
          <br />
          <div className="Termstxt">
            You understand that Parcel-King will issue a refund or return
            estimation on receipt of your completed and signed forms. You
            understand that receipt of your completed documentation authorises
            Parcel-King to submit your application to the relevant tax office.
            Should you wish to cancel your application, you will contact Parcel
            King immediately.
          </div>
          <br />
          <div className="Termstxt">
            You understand that while Parcel-King will make every effort to
            recall your application, this may not be possible.
          </div>
          <br />
          <h6 className="TermsSubHeading1">Emails</h6>
          <div className="Termstxt">
            All emails to the customer will be sent to the email address
            provided by the customer at registration. Parcel-King will expect
            that the customer will ensure there is sufficient Storage and/or
            Cloud Storage according to the customer’s country laws and
            regulations. The customer must ensure that there is space in his/her
            inbox to receive all emails and that the customer checks his/her
            email regularly - regularly being about three times a week.
          </div>
          <h6 className="TermsSubHeading1">Minimum Fee</h6>
          <div className="Termstxt">
            Parcel-King has a minimum fee for each tax refund application.
            Depending on the tax country from which the refund is being
            organised, a contingency fee is charged where the refund amount is
            above the minimum fee amount. For information about the fees, email
            info@taxgoglobal.com. Parcel-King will provide information of the
            fee applicable per application per tax country.
          </div>
          <br />
          <div className="Termstxt">
            For additional value-added services provided by Parcel-King to the
            customer, additional fees will be charged to cover associated
            administrative and management costs. Such additional services
            include, but are not limited to, document retrieval, personal number
            (e.g. TIN, SSN, PPS, NIN, TFN, VAT) verification, residency and
            non-residency document organisation where relevant, amendment
            preparation for previous returns, and certain payment options. For
            information about the fee for the additional services rendered for
            an individual case, the customer should email info@taxgoglobal.com.
            Parcel-King will provide information of the fee applicable per
            customer case.
          </div>
          <h6 className="TermsSubHeading1">Refund Issuance</h6>
          <div className="Termstxt">
            Parcel-King will send the refund to the customer as agreed per
            communication with the customer.
          </div>
          <br />
          <div className="Termstxt">
            Depending on the tax being refunded, the usual method is by bank
            transfer. Tax refunds received by bank transfer can be transferred
            to the customer’s account in any currency they choose. Customers may
            also opt to receive their tax refund by cheque, however in this case
            Parcel-King cannot guarantee that the cheque can be issued in the
            customer’s preferred currency. Customers wishing to receive their
            tax refund by cheque should notify Parcel-King of the currency they
            wish to receive their cheque in as early as possible in the tax
            refund process. Parcel-King will contact the customer at the time of
            refund to offer different payment options and at this stage should
            be supplied with bank details for the refund transfer.
          </div>
          <br />
          <div className="Termstxt">
            Where Parcel-King receives the money from the tax office, it will
            deduct its fee, plus necessary tax, from the amount sent to the
            customer. In some tax refunds cases, the customer will pay the fee
            and necessary tax in advance and the cheque is sent in full to the
            customer by the tax authorities.
          </div>
          <h6 className="TermsSubHeading1">Return Payment</h6>
          <div className="Termstxt">
            Parcel-King is not obliged to pay taxes for customers, as most tax
            authorities allow direct debit from customer’s accounts. After
            filing, Parcel-King will set up the direct debit mandate in the case
            of no mandate setup, with additional requirements of the customer’s
            bank details. Where the customer prefers Parcel-King to pay the tax
            on behalf of the customer, the customer will first transfer the tax
            amount to Parcel-King or sign a credit agreement with Parcel-King.
          </div>
          <h6 className="TermsSubHeading1">Bank Charges</h6>
          <div className="Termstxt">
            In the case of payment by cheque, Parcel-King has no control over
            the fees charged locally by banks in cashing the cheque. Parcel-King
            has developed different payment methods in many markets in order to
            offer the customer a choice. Parcel-King will inform the customer,
            to the best of its knowledge, what the local charges are by local
            banks. Parcel-King will not be liable for any changes in local bank
            charges, any currency exchange rates by the banks or any time period
            that the bank takes to cash the cheque. Parcel-King will apply an
            administration fee for the provision of additional payment options,
            which are highly administrative in nature. Parcel-King informs the
            customer when the choice of payment option is offered.
          </div>
          <br />
        </Container>
        <Container className="TermsMainBox">
          <p className="c4">
            <span className="c3 font-weight-bold">Important legal information</span>
          </p>
          <p className="c1">
            <span className="c0">
              By accessing this website you consent to the following terms and
              conditions of use.&nbsp; In these terms and conditions
              &#39;Parcel-King&#39; means Jayden & Preston Ltd, a Tax GO
              Company and their related companies that host services on this
              website.&nbsp; &quot;This website&quot;
              includes&nbsp;https://www.parcel-king.com/#/.
            </span>
          </p>
          <p className="c4">
            <span className="c3 font-weight-bold">Security and privacy</span>
          </p>
          <p className="c1">
            <span className="c5">
              Parcel-King takes the security and privacy of its systems and its
              customers&#39; data very seriously. We constantly review our
              systems and data to ensure the best possible service to our
              customers. The authorities has created specific offences for
              unauthorised actions against computer systems and data.
              Parcel-King will investigate any such actions with a view to
              prosecuting and/or taking civil proceedings to recover damages
              against those responsible. By using this website, you agree to the
              capture and use of your data in accordance with the terms laid out
              in the website&#39;s Privacy Policy and the customer terms and
              conditions relevant to the different services hosted on the
              website. For more information on our&nbsp;
            </span>
            <span className="c6">
              <Link className="c2" to="/privacy">
                Privacy Policy
              </Link>
            </span>
            <span className="c0">&nbsp;please click here.</span>
          </p>
          <p className="c4">
            <span className="c3 font-weight-bold">Exclusions and limitations</span>
          </p>
          <p className="c1">
            <span className="c0">
              The information on this website is provided on an &quot;as
              is&quot; basis. To the fullest extent permitted by law
              Parcel-King:
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              - (a) excludes all representations and warranties relating to this
              website and its contents, including in relation to any
              inaccuracies or omissions in this website; and&nbsp; - (b)
              excludes all liability for loss or damage arising out of or in
              connection with your use of this website. This includes, without
              limitation, direct loss, loss of business or profits (whether or
              not the loss of such profits was foreseeable, arose in the normal
              course of things or you have advised Parcel-King of the
              possibility of such potential loss) and indirect, consequential
              and incidental damages.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              Parcel-King does not however exclude liability for death or
              personal injury caused by its negligence.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              The above exclusions and limitations apply only to the extent
              permitted by law. None of your statutory rights as a consumer that
              cannot be excluded or limited are affected.
            </span>
          </p>
          <p className="c4">
            <span className="c3 font-weight-bold">Content changes and accuracy of information</span>
          </p>
          <p className="c1">
            <span className="c0">
              Parcel-King may make changes to the contents of this website,
              including to the descriptions and prices of goods and services
              advertised, at any time and without notice.&nbsp; Personalisation
              of the site based on user activity and preferences may change the
              appearance and contents of this website at any time. Some
              information on this website is taken from sources external to
              Parcel-King or is of a type that changes frequently. In
              particular, you should confirm offline the accuracy and currency
              of information about prices, compensation limits and conditions,
              delivery times, packaging requirements, posting restrictions and
              prohibitions and conditions of posting in countries outside the EU
              before relying on it.&nbsp; Parcel-King does not necessarily share
              the opinions expressed or endorse the material appearing in
              external content published by it on this website.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              Parcel-King, registered in Ireland, number 662074, registered
              office: Unit 3 Damastown Way, Damastown Industrial Park, Dublin 15. D15 XW7K.
              Jayden & Preston Ltd is a Tax GO Company. Parcel-King are
              Amazon Prime and FEDEX Delivery Partners.
            </span>
          </p>
          <p className="c4">
            <span className="c3 font-weight-bold">Contracts</span>
          </p>
          <p className="c1">
            <span className="c0">
              Nothing on this website constitutes an offer by Parcel-King to
              sell any goods or services.&nbsp; Subject to the customer terms
              and conditions of the relevant service, no contract will be formed
              until your order or application has been acknowledged and accepted
              by Parcel-King.
            </span>
          </p>
          <p className="c4">
            <span className="c3 font-weight-bold">Cancellation of registration</span>
          </p>
          <p className="c1">
            <span className="c0">
              Parcel-King may cancel your registration for this website or any
              service hosted on it if you breach these terms and conditions or
              the customer terms and conditions for any service.
            </span>
          </p>
          <p className="c4">
            <span className="c3 font-weight-bold">Discussion groups and bulletin boards</span>
          </p>
          <p className="c1">
            <span className="c0">
              Unless otherwise stated, Parcel-King does not edit or monitor the
              content of any discussion group or bulletin board hosted on this
              website.&nbsp; The content of discussion groups and bulletin
              boards is provided by third parties.&nbsp; Parcel-King is not the
              publisher of it.&nbsp; However, Parcel-King may remove content
              from any discussion group or bulletin board at its discretion.
            </span>
          </p>
          <p className="c4">
            <span className="c3 font-weight-bold">Links to this website</span>
          </p>
          <p className="c1">
            <span className="c0">
              You may not create a link to any page of this website without
              Parcel-King&#39;s prior written consent.&nbsp; If you do create a
              link to a page of this website you do so at your own risk and the
              exclusions and limitations set out&nbsp;above will apply to your
              use of this website by linking to it.
            </span>
          </p>
          <p className="c4">
            <span className="c3 font-weight-bold">Links from this website</span>
          </p>
          <p className="c1">
            <span className="c0">
              Parcel-King does not monitor or review the content of other
              parties&#39; websites that are linked from this website. Opinions
              expressed or material appearing on such websites are not
              necessarily shared or endorsed by Parcel-King. Parcel-King is not
              the publisher of such opinions or material.
            </span>
          </p>
          <p className="c4">
            <span className="c3">Availability</span>
          </p>
          <p className="c1">
            <span className="c0">
              Unless otherwise stated, the products and services featured on
              this website are only available within the EU, or in relation to
              postings from the Republic of Ireland.
            </span>
          </p>
          <p className="c4">
            <span className="c3 font-weight-bold">Software</span>
          </p>
          <p className="c1">
            <span className="c0">
              Although Parcel-King uses reasonable endeavours to ensure that any
              software made available by it on this website is suitable for
              downloading, installation and use by third parties, all such
              software is provided &quot;as is&quot; without any warranty.&nbsp;
              Specifically, and without limitation, Parcel-King does not warrant
              that any such software is virus free, without defects, Year
              2000-compliant, compatible with other software or operating
              systems or suitable for any specific purpose.&nbsp;
              Parcel-King&nbsp; accepts no liability for any loss or damage
              caused by the downloading, installation or use of any such
              software, and the general exclusions and limitations above apply
              to such downloading, installation or use. Parcel-King does not
              however exclude liability for death or personal injury caused by
              its negligence.&nbsp; The above exclusions and limitations apply
              only to the extent permitted by law.&nbsp; None of your statutory
              rights as a consumer that cannot be excluded or limited are
              affected.
            </span>
          </p>
          <p className="c4">
            <span className="c3 font-weight-bold">General</span>
          </p>
          <p className="c1">
            <span className="c0">
              These terms and conditions are governed by the laws of the
              European Union. All disputes arising out of your access to this
              website are subject to the exclusive jurisdiction of the EU
              courts. If any of these terms or conditions is deemed invalid or
              unenforceable for any reason (including, but not limited to the
              exclusions and limitations set out above) then the invalid or
              unenforceable provision will be severed from these terms and
              conditions and the remaining provisions will continue to
              apply.&nbsp;
            </span>
          </p>
          <p className="c1">
            <span className="c5 font-weight-bold">Further information on our&nbsp;</span>
            <span className="c6">
              <Link className="c2" to="/tradingterms">
                general terms and conditions of trading
              </Link>
            </span>
            <span className="c0">.</span>
          </p>
          <p className="c1">
            <span className="c0">
              Parcel-King is a company registered in Republic of Ireland,
              <br />
              number 662074,
              <br />
              <br />
              <p className=" font-weight-bold" >Registered office:{" "}</p>
              <div>
              Unit 3 Damastown Way, Damastown Industrial Park, <br />
              Dublin 15. D15 XW7K.
              </div>
              VAT registration number: <b>IE3650259BH</b>
            </span>
          </p>
          <p className="c7">
            <span className="c9"></span>
          </p>
        </Container>
        <Footer />
      </div>
    );
  }
}
