export const CheckPoligon = (point) => {
  var vs = [
    [53.394587, -6.447445],
    [53.422449, -6.420065],
    [53.416519, -6.404959],
    [53.422558, -6.38539],
    [53.403018, -6.38436],
    [53.416605, -6.374575],
    [53.422235, -6.340243],
    [53.398294, -6.334063],
    [53.406689, -6.286684],
    [53.409557, -6.231752],
    [53.402601, -6.186433],
    [53.423581, -6.156721],
    [53.406703, -6.150541],
    [53.401896, -6.131315],
    [53.395656, -6.114492],
    [53.390285, -6.081705],
    [53.393615, -6.066599],
    [53.385787, -6.047373],
    [53.384328, -6.044905],
    [53.373567, -6.046799],
    [53.366068, -6.054695],
    [53.361193, -6.052206],
    [53.364218, -6.066969],
    [53.36038, -6.07611],
    [53.37005, -6.096774],
    [53.378809, -6.10261],
    [53.384725, -6.101323],
    [53.387531, -6.108833],
    [53.379934, -6.117712],
    [53.352537, -6.164919],
    [53.27103, -6.09222],
    [53.250137, -6.112648],
    [53.225688, -6.126381],
    [53.229185, -6.14698],
    [53.2484, -6.146293],
    [53.245014, -6.186462],
    [53.249434, -6.205407],
    [53.26864, -6.262399],
    [53.274084, -6.286775],
    [53.266798, -6.370202],
    [53.28107, -6.430627],
    [53.293028, -6.444017],
    [53.328727, -6.429346],
    [53.349844, -6.40291],
    [53.372388, -6.403596],
    [53.395125, -6.439302],
  ];
  var x = point[0],
    y = point[1];
  var inside = false;
  for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    var xi = vs[i][0],
      yi = vs[i][1];
    var xj = vs[j][0],
      yj = vs[j][1];

    var intersect =
      yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }
  return inside;
};
