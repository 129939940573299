import React from "react";
import whatsappIcon from "../../Assets/whatsapp.png";
function WhatsApp() {
  const myStyle = {
    position: "fixed",
    bottom: "20px",
    right: "50px",
    zIndex: "9999999px",
    borderRadius: "50%",
    transitionDelay: "1s",
  };

  const whStyle = {
    width: "50px",
    height: "50px",
  };

  return (
    <div style={myStyle}>
      <a
        target="blank"
        rel="noopener noreferrer"
        href="https://api.whatsapp.com/send/?phone=353876377889"
      >
        <div className="whatsappIcon">
          <img style={whStyle} src={whatsappIcon} alt=""></img>
        </div>
      </a>
    </div>
  );
}

export default WhatsApp;
