import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";

function AboutSection() {
  return (
    <Container>
      <h1 className="home-txt4">About Parcel-King</h1>
      <br />
      <p className="home-txt9">
        <b>Parcel-King</b> courier service & parcel delivery in
        Ireland.We provide the cheapest courier service starting at just 8 euros
        with a safe delivery guarantee. Book now!
      </p>
      <h3 className="home-txt8">From Connecting Families</h3>
      <br />
      <p className="home-txt9">
        Do you have a letter or package that you want to send to your loved
        ones? Are you dreaded or confused with the courier process? If yes, we
        are here to assist you. Parcel-King will deliver your packages/letters
        safely to your family and friends, without you having to be concerned
        about its handling and delivery.
      </p>
      <h3 className="home-txt8"> To Helping Businesses </h3>
      <br />
      <p className="home-txt9">
        Delivering business packages and letters needs utmost security.
        Parcel-King ensures that we maintain the integrity of your business and
        have your couriers delivered safely and on time.
      </p>
    </Container>
  );
}
export default withRouter(AboutSection);
