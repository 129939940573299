import React from 'react';
import { Modal, Button } from 'antd';
import { AlertOutlined } from '@ant-design/icons';


const InfoModal = ({ isVisible, close }) => {
  return (
    <Modal
    title={
        <span>
          <AlertOutlined style={{ color: '#faad14', marginRight: '8px' }} />
          Important Notice
        </span>
      }      visible={isVisible}
      onCancel={() => close()}
      footer={[
        <Button key="close" onClick={() => close()}>
          Close
        </Button>,
      ]}
    >
      <p>Booking services have been temporarily suspended. We apologize for the inconvenience and appreciate your understanding.</p>
      </Modal>
  );
};

export default InfoModal;
